import React from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";

const dummyPosts = {
  1: { id: 1, title: "게시판 제목1", author: "작성자1", content: "게시판 내용1입니다." },
  2: { id: 2, title: "게시판 제목2", author: "작성자2", content: "게시판 내용2입니다." },
  3: { id: 3, title: "게시판 제목3", author: "작성자3", content: "게시판 내용3입니다." },
  4: { id: 4, title: "게시판 제목4", author: "작성자4", content: "게시판 내용4입니다." },
  5: { id: 5, title: "게시판 제목5", author: "작성자5", content: "게시판 내용5입니다." },
};

const BoardDetail = () => {
  const { postId } = useParams();
  const post = dummyPosts[postId];

  if (!post) {
    return <div>게시글이 존재하지 않습니다.</div>;
  }

  return (
    <div>
      <Typography variant="h5">{post.title}</Typography>
      <Typography variant="subtitle1">{post.author}</Typography>
      <Typography variant="body1">{post.content}</Typography>
    </div>
  );
};

export default BoardDetail;