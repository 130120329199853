// getNaverToken.js
import axios from 'axios';

const getNaverToken = async (code, state) => {
    const url = 'https://nid.naver.com/oauth2.0/token';
    const params = {
        grant_type: 'authorization_code',
        client_id: 'mQY7qtmogt3oEJA1UR64',
        client_secret: '5YTfcxVg5k',
        code,
        state,
    };

    const response = await axios.post(url, params);
    const token = response.data.access_token;

    return token;
};

export default getNaverToken;