// callback.js
import React, { useState, useEffect } from 'react';
import getNaverToken from '../../api/snsApi';
import { handleFirebaseLogin, handleNaverSignIn } from '../../firebase';

const Callback = () => {
  const [code, setCode] = useState(null);
  const [error, setError] = useState(null);


  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const code = params.get('code');
    const state = params.get('state');

    if (code && state) {
      getNaverToken(code, state)
        .then((token) => {
          // Firebase 인증 로직 처리

          // 부모 창이 있는 경우 (팝업으로 열린 경우)
          if (window.opener) {
            // 부모 창의 함수 호출 (인증 코드 전달)
            window.opener.naverAuthCallback(token);

            // 팝업 창 닫기
            window.close();
          }
          console.log(token);

          console.log(token);
          // handleFirebaseLogin(token);
          handleNaverSignIn();
        })
        .catch((error) => {
          window.opener.naverAuthCallback('failed');
          window.close();
          setError(error);
        });
    }
  }, []);



  return (
    <div>
      {error ? (
        <p>에러가 발생했습니다.</p>
      ) : (
        <p>로그인 성공!</p>
      )}
    </div>
  );
};

export default Callback;