export const recentPosts = [
    { category: 'Technology', title: 'Wearable Fitness Tech', date: '2024-08-11' },
    { category: 'Nutrition', title: 'Pre-Run Nutrition Tips', date: '2024-08-09' },
    { category: 'Nutrition', title: 'Pre-Run Nutrition Tips', date: '2024-08-09' },
    { category: 'Spor2ts', title: 'Best Running Shoes', date: '2024-08-10' },
    { category: 'S3ports', title: 'Best Running Shoes', date: '2024-08-10', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-08-01' },
    { category: 'He4alth', title: 'Benefits of Morning Run', date: '2024-07-23' },
    { category: 'Hea5th', title: 'Benefits of Morning Run', date: '2024-07-20' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-07-18', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-07-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-06-29' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-06-28', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-05-18' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-05-10', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-05-09' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-08' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-04-07' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-06', image: 'https://via.placeholder.com/50' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-03-05' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-04' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-02-03' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-02' },
    { category: 'Health', title: 'Benefits of Morning Run', date: '2024-01-01' },
    // 추가 더미 데이터
];