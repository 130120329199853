import { createSlice } from '@reduxjs/toolkit';

export const userSlice2 = createSlice({
    name: 'user',
    initialState: null,
    reducers: {
        setUser: (state, action) => action.payload,
        clearUser: () => null,
    },
});

export const { setUser, clearUser } = userSlice2.actions;
export default userSlice2.reducer;
