import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const UserProfile2 = () => {
    const user = useSelector(state => state.user);

    return (
        <Box sx={{ padding: '20px' }}>
            <Typography variant="h6">{user.displayName}님 환영합니다.</Typography>
            <Typography variant="body1">Email: {user.email}</Typography>
        </Box>
    );
};

export default UserProfile2;
