import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice2';

const store2 = configureStore({
    reducer: {
        user: userReducer,
    },
});

export default store2;
