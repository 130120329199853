import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Box
            sx={{
                padding: '10px',
                backgroundColor: '#f5f5f5',
                textAlign: 'center'
            }}
        >
            <Typography variant="body2">© 2024 Running Mania. All rights reserved.</Typography>
        </Box>
    );
};

export default Footer;
