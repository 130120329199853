import React from 'react';
import { Box, Grid } from '@mui/material';
import Header from '../components/Header';
import RunningImage from '../components/RunningImage';
import Login from '../components/Login';
import UserProfile from '../components/UserProfile2';
import PopularPosts from '../components/PopularPosts2';
import RecentPosts from '../components/RecentPosts2';
import MenuList from '../components/MenuList';
import Footer from '../components/Footer2';
import { useSelector } from 'react-redux';
import { ddf } from '../App';

const MainPage2 = () => {
    const user = useSelector(state => state.user);

    console.log(ddf);

    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            {/* 헤더 영역 */}
            <Header />

            {/* 바디 영역 */}
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    {/* 8:4 비율의 첫 번째 영역 */}
                    <Grid item xs={8}>
                        <RunningImage />
                    </Grid>
                    <Grid item xs={4}>
                        {user ? <UserProfile /> : <Login />}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {/* 8:4 비율의 두 번째 영역 */}
                    <Grid item xs={4}>
                        <PopularPosts />
                    </Grid>
                    <Grid item xs={4}>
                        <RecentPosts />
                    </Grid>
                    <Grid item xs={4}>
                        <MenuList />
                    </Grid>
                </Grid>
            </Box>

            {/* 푸터 영역 */}
            <Box sx={{ mt: 'auto' }}>
                <Footer />
            </Box>
        </Box>
    );
};

export default MainPage2;
