import React, { useState } from "react";
import { signInWithEmail, signUpWithEmail, signInWithGoogle } from "../../firebase";
import { TextField, Button, Box } from "@mui/material";
import NaverLogin from "../NaverLogin";

const Auth = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSignUp, setIsSignUp] = useState(false);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSignUp) {
      await signUpWithEmail(email, password);
    } else {
      await signInWithEmail(email, password);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <h2>{isSignUp ? "회원가입" : "로그인"}</h2>
      <form onSubmit={handleSubmit}>
        <TextField label="Email" value={email} onChange={handleEmailChange} fullWidth margin="normal" />
        <TextField label="Password" type="password" value={password} onChange={handlePasswordChange} fullWidth margin="normal" />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {isSignUp ? "회원가입" : "로그인"}
        </Button>
      </form>
      <Button variant="outlined" color="primary" fullWidth onClick={() => setIsSignUp(!isSignUp)}>
        {isSignUp ? "로그인으로 이동" : "회원가입으로 이동"}
      </Button>
      <Button variant="contained" color="secondary" fullWidth onClick={signInWithGoogle} sx={{ mt: 2 }}>
        Google 로그인
      </Button>
      <NaverLogin />
    </Box>
  );
};

export default Auth;