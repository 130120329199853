/**
 * UserComponent.js는 Redux 상태를 활용하는 UI 컴포넌트를 정의합니다. 
 */
// src/components/UserComponent.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../store/userSlice';

const UserComponent = ({ userId }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.user);
    const status = useSelector((state) => state.user.status);
    const error = useSelector((state) => state.user.error);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(getUser(userId));
        }
    }, [status, dispatch, userId]);

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <h2>User Info</h2>
            {user && (
                <div>
                    <p>Name: {user.name}</p>
                    <p>Email: {user.email}</p>
                </div>
            )}
        </div>
    );
};

export default UserComponent;
