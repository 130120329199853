import React from 'react';
import { Box, Button } from '@mui/material';

const menuItems = Array.from({ length: 20 }, (_, i) => `Menu Item ${i + 1}`);

const MenuList = () => {
    return (
        <Box
            sx={{
                height: '400px',
                overflowY: 'auto',
                padding: '20px',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '10px',
            }}
        >
            {menuItems.map((item, index) => (
                <Button key={index} variant="text" sx={{ flexShrink: 0 }}>
                    {item}
                </Button>
            ))}
        </Box>
    );
};

export default MenuList;
