import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Avatar, Button, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { popularPosts } from './json/popularData';

const POSTS_PER_PAGE = 10;

const PopularPosts2 = () => {
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const totalPages = Math.ceil(popularPosts.length / POSTS_PER_PAGE);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleClick = (post) => {
        // 원하는 경로로 이동하게끔 설정합니다.
        navigate(`/post/${post.title}`);
    };

    const displayedPosts = popularPosts.slice(
        (page - 1) * POSTS_PER_PAGE,
        page * POSTS_PER_PAGE
    );

    return (
        <Box sx={{ padding: '20px' }}>
            <Typography variant="h6">인기글</Typography>
            <Box
                sx={{
                    height: '380px', // 리스트의 높이를 제한합니다.
                    overflowY: 'auto', // 넘치는 내용은 스크롤을 통해 볼 수 있게 합니다.
                    marginBottom: '20px', // 페이징 영역과의 간격을 유지합니다.
                }}
            >
                <List>
                    {displayedPosts.map((post, index) => (
                        <ListItem
                            key={index}
                            sx={{
                                marginBottom: '0px',
                                paddingY: '2px',
                                backgroundColor: index % 2 === 1 ? '#f5f5f5' : 'transparent',
                                cursor: 'pointer'
                            }}
                            onClick={() => handleClick(post)} // 클릭 시 이동하는 함수
                        >

                            <ListItemText
                                primary={
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {<Avatar
                                                sx={{ width: 24, height: 24, marginRight: '10px' }}
                                            >
                                                {post.category.charAt(0)}
                                            </Avatar>}
                                            {`${post.category} - ${post.title}`}
                                            {post.image && (
                                                <Box
                                                    component="img"
                                                    src={post.image}
                                                    alt={post.title}
                                                    sx={{ marginLeft: '10px', width: '14px', height: '14px' }}
                                                />
                                            )}
                                        </Box>
                                        <Typography variant="body2" color="textSecondary">
                                            {post.date}
                                        </Typography>
                                    </Box>
                                }
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Pagination
                count={totalPages}
                page={page}
                onChange={handleChangePage}
                color="primary"
                sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
            />
        </Box>
    );
};

export default PopularPosts2;