import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth from "./components/sns/Auth";
import BoardList from "./components/BoardList";
import BoardDetail from "./components/BoardDetail";
import Callback from "./components/sns/Callback";
import UserComponent from './components/UserComponent';
import MainPage2 from "./page/MainPage2";
import Editor from "./components/editor/Editor";

export var ddf = 1;
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage2 />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/2" element={<UserComponent userId={1} />} />
        <Route path="/board" element={<BoardList />} />
        <Route path="editor" element={<Editor />} />
        <Route path="/post/:postId" element={<BoardDetail />} />
        <Route path="/callback/naver_login" element={<Callback />} />
      </Routes>
    </Router>
  );
};

export default App;