import React from "react";
import { Link } from "react-router-dom";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

const dummyPosts = [
  { id: 1, title: "게시판 제목1", author: "작성자1" },
  { id: 2, title: "게시판 제목2", author: "작성자2" },
  { id: 3, title: "게시판 제목3", author: "작성자3" },
  { id: 4, title: "게시판 제목4", author: "작성자4" },
  { id: 5, title: "게시판 제목5", author: "작성자5" },
];

const BoardList = () => {
  return (
    <div>
      <Typography variant="h4">게시판</Typography>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {dummyPosts.map((post) => (
          <ListItem key={post.id} component={Link} to={`/post/${post.id}`}>
            <ListItemText primary={post.title} secondary={post.author} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default BoardList;