import React from 'react';
import { Box } from '@mui/material';

const RunningImage = () => {
    return (
        <Box
            sx={{
                height: '300px',
                backgroundImage: 'url(/path/to/running-image.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        />
    );
};

export default RunningImage;
