import React from 'react';
import { Box, Button } from '@mui/material';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { setUser } from '../store/userSlice2';

const Login = () => {
    const dispatch = useDispatch();
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    const handleLogin = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                dispatch(setUser(result.user));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <Box sx={{ padding: '20px' }}>
            <Button variant="contained" onClick={handleLogin}>
                Login with Google
            </Button>
        </Box>
    );
};

export default Login;
