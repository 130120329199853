import React from 'react';
import { Box, Typography } from '@mui/material';

const Header = () => {
    return (
        <Box
            sx={{
                height: '80px',
                backgroundColor: '#2222DA',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
            }}
            onClick={() => window.location.href = '/'}
        >
            <Typography variant="h4" color="white">
                Running Mania
            </Typography>
        </Box>
    );
};

export default Header;
