/**
 * user API 호출 로직
 */
// src/api/userApi.js
import axios from 'axios';

export const fetchUser = async (userId) => {
    const response = await axios.get(`https://jsonplaceholder.typicode.com/users/${userId}`);
    return response.data;
};


// src/
// |-- api/
// |   |-- userApi.js
// |-- store/
// |   |-- userSlice.js
// |   |-- store.js
// |-- components/
// |   |-- UserComponent.js
// |-- App.js
// |-- index.js